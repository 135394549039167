<template>
	<div style="margin-bottom: 20px;" align="center">
		<ins class="adsbygoogle" style="display:block;background-color: red;" data-ad-client="ca-pub-8147879111198627"
			data-ad-slot="4026586674" data-ad-format="auto" data-full-width-responsive="true"></ins>
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	export default {
		name: '',
		components: {

		},
		data() {
			return {

			}
		},
		created() {

		},
		mounted() {
			window.addAds();
		},
		methods: {

		},
	}
</script>

<style scoped lang="scss">
</style>
